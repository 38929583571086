 .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  

    


  /* Responsive layout for mobile devices */
  @media (max-width: 640px) {
    .video-responsive {
      padding-bottom: 75%; /* Adjust aspect ratio for a smaller screen */
   }
  }
 
  