/* Imported Font */

@font-face {
  font-family: "Madelyn";
  src: url('../src/assets/fonts/madelyn/Madelyn-Trial-Regular.ttf') format(truetype);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant";
  src: url('../src/assets/fonts/cormorant/CormorantGaramond-Regular.ttf') format(truetype);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant-Bold";
  src: url('../src/assets/fonts/cormorant/CormorantGaramond-Bold.ttf') format(truetype);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Agradir";
  src: url('../src/assets/fonts/agrandir/Agrandir-Regular.otf') format(opentype);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Agradir-Bold";
  src: url('../src/assets/fonts/agrandir/Agrandir-TextBold.otf') format(opentype);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ovo";
  src: url('../src/assets/fonts/ovo/Ovo-Regular.ttf') format(truetype);
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: "Madelyn", sans-serif !important;
}

p {
  font-family: "Agradir", sans-serif;
}

.font-agradir {
  font-family: "Agradir", sans-serif !important;
}

.font-madelyn {
  font-family: "Madelyn", sans-serif !important;
}

.font-agradir-bold {
  font-family: "Agradir-Bold", sans-serif !important;
}

.font-cormorant {
  font-family: "Cormorant", sans-serif !important;
}

.primary-font-color {
  color: #C85103 !important 
}

.secondary-font-color {
  color: #6D3724 !important 
}

.third-font-color {
  color : #A8623C !important
}

.white-color {
  color: #fff;
}

.global-title-template{
    font-size: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #6D3724;
    line-height: 120px;
    letter-spacing: 0.068em;
}

.text-letter-spacing {
  letter-spacing: 2px;
}

.background-gray{
  background: rgb(244, 244, 244) !important;
}

.background-white{
  background: #fff !important;
}

.global-text-template{
  font-size: 1rem;
  text-align: center;
  color: #6D3724;
  line-height: 30px;
  letter-spacing: 0.068em;
  font-style: normal;
  font-kerning: none;
  text-decoration: none
}

.font-weight-bold{
  font-weight: bold;
}

.font-style-italic {
  font-style: italic;
}


.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.margin-top-55{
  margin-top: 35px !important
}

.margin-top-204{
  margin-top: 204px !important
}

.margin-top-neg-22{
  margin-top: -22px;
}

.margin-left-110 {
  margin-left: 110px !important;
}

.margin-bottom-140 {
  margin-bottom: 140px;
}

.global-standard-height {
  height: 100vh !important;
}

a.wedding-button, a.wedding-button:hover, a.wedding-button:active{
  background-color: #CC5315;
  border: 2px solid #6D3724; /* Outline-style border */
  font-family: 'Agradir-Bold';
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

.image-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full-width container */
  height: auto; /* Let the content adjust */
  overflow: hidden; /* Prevent overflow */
}

.image-container{
  max-width: 100%;
  height: auto;
}

.font-size-10{
  font-size: 0.6rem;
}

.margin-top-neg-20 {
  margin-top: -20px !important;
}

.font-size-25{
  font-size: 0.9rem;
}

.font-size-20{
  font-size: 1rem;
}

.font-size-30{
  font-size: 2rem;
}

.font-size-40 {
  font-size: 2.5rem;
}

.font-size-50 {
  font-size: 4rem;
}

.font-size-15 {
  font-size: 0.7rem;
}

.font-size-16{
  font-size: 1rem;
}

.font-size-18{
  font-size: 1rem;
}

.upIndex{
  z-index: 8 !important;
  position: relative;
}

/* Animations */
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0); /* Start at the original position */
  }
  30% {
    transform: translateY(-10px); /* Move up */
  }
  50% {
    transform: translateY(0); /* Return to original position */
  }
  70% {
    transform: translateY(-5px); /* Slight move up */
  }
  100% {
    transform: translateY(0); /* End at original position */
  }
}

@keyframes pop {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(1.1);
  }
}



/* Laptop 150% */
@media (max-width: 1300px) {
  .global-standard-height {
    height: 120vh !important;
  }

}

/* Laptop 175% */
@media (max-width: 1100px) {
  .global-standard-height {
    height: 140vh !important;
  }

}

/* Laptop 200% */
@media (max-width: 960px) {
  .global-standard-height {
    height: 160vh !important;
  }

}



/* Mobile */
@media (max-width: 767px) {
  .global-title-template{
    font-size: 4rem;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 63px;
  }

  .global-text-template{
    font-size: 3rem;
  }

  .font-size-40 {
    font-size: 1.5rem;
  }

  /* .margin-top-55 {
    margin-top: 12px !important;
  } */

  .margin-bottom-140 {
    margin-bottom: 60px;
  }

  /* .font-size-30 {
    font-size: 20px;
  } */

  .global-standard-height {
    height: 70vh !important;
  }

  /* .font-size-40{
    font-size: 22px !important;
  } */

  /* .font-size-18 {
    font-size: 13px !important;
  }

  .font-size-25 {
    font-size: 16px !important;
  }

  .font-size-15{
    font-size: 12px !important
  }

  .font-size-16{
    font-size: 14px !important
  } */

}

