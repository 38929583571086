 .information-section {
    background: #fff !important;
    padding: 4rem 0;
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  
  .information-section.visible {
    opacity: 1;
  }
  
  .snap-icons {
    display: flex;
    justify-content: center;
    gap: 8px; /* Space between icons */
    align-items: center;

  }
  
  .content-icon .icon-class1 {
    color: #D1BCA3; /* Default icon color */
    transition: color 0.3s ease;
    cursor: pointer;
    font-size:1rem;
  }
  
  .content-icon2 .icon-class2 {
    color: #fff; /* Default icon color */
    transition: color 0.3s ease;
    cursor: pointer;
    font-size:1rem;
  }
  
  .content-icon .icon-class1:hover {
    color: #CC5315; /* Change color on hover */
  }

  .margin-left-90{
    margin-left: 90px;
  }

  .color-palette {
    display: flex;
    gap: 5px; /* Space between circles */
    flex-wrap: wrap;
  }
  
  .color-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Makes it a circle */
    border: 2px solid #ddd; /* Optional border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for effect */
    cursor: pointer; /* Changes cursor on hover */
    transition: transform 0.3s ease; /* Smooth scale effect */
  }
  
  .color-circle:hover {
    transform: scale(1.1); /* Slightly enlarges circle on hover */
  }

  .img-4-template {
    width:90%;
  } 

  .img-3-template {
    width:100%;
  }

  


  @media (max-width: 767px) {
   

    .img-4-template {
      width:23%;
    } 
  
    .img-3-template {
      width:25%;
    }
  

    .color-palette{
      margin-left: 29%;
    }

    .mobile-center-text{
      text-align: center !important;
      z-index: 30;
    }

    .color-circle{
      width: 25px;
      height: 25px;
    }

    
  }
  