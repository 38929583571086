/* Basic styling for the Navbar */
.navbar {
    padding: 1rem;
    transition: background-color 0.3s ease-in-out;
    font-family: "Cormorant";
    
  }
  
  /* Background color when scrolled */
  .navbar-scrolled {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .mobile-view-nav, .menu-overlay, .side-panel  {
    display: none;
  }

  .web-view-nav{
    display:block;
    margin-top: 40px !important;
  }

  
  .navbar-logo img {
    max-height: 40px;
  }
  
  .navbar-nav .nav-link {
    color: white;
    margin-right: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);  /* Black shadow for outline effect */
    font-weight: bold;
    margin-top: -57px;
    font-size: 1.3rem;
    
  }
  
  .nav-link:hover, .nav-link.active {
    color: #CC5315 !important; /* Lighter brown on hover */
  }

  .logo-template{
    font-family: "Ovo", sans-serif;
    color: #C85103;
    font-size: 3rem;
    margin-left: 59px;
  }

  .nav-rsvp {
    border: 2px solid #C85103 !important;
    /* background-color: white !important; */
    border-radius: 5px; /* Optional: add rounded corners */
    color: #C85103 !important; /* Text color */
    font-weight: bold; /* Optional: make the text bold */
    text-align: center; /* Center the text */
    padding: -2px;
  }

  
  
  .nav-rsvp:hover, .nav-rsvp.active {
    background-color: #f0f0f0; /* Optional: add a hover effect */
    color: black;
    text-decoration: none;
  }


  /* css if it have image */

  .mobile-rsvp-nav{
    display: none;
  }

  .heart-wrapper {
    position: absolute;
    width: 50%;
    animation: pop 1s ease-in-out infinite alternate;
    left: -3px;
    top: -4px;
  }

  .nav-rsvp-border{
    width: 140px;
  }

  .upIndex:hover, .upIndex:active{
    color: white !important;
  }


     

   /* Responsive adjustments mobile */
   @media (max-width: 767px) {

    .mobile-view-nav, .menu-overlay, .side-panel, .mobile-rsvp-nav  {
      display: block !important;
    }

    
    .web-view-nav{
      display:none !important;
    }

    .heart-wrapper {
      position: absolute;
      width: 11%;
      left: 196px;
      top: -21px;
      animation: pop 1s ease-in-out infinite alternate;
      z-index: 4 !important;
    }

    .mobile-rsvp-direct {
      font-family: 'Madelyn', sans-serif;
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
      transition: background-color 0.3s ease, color 0.3s ease;
      font-size: 16px;
      text-decoration: none !important;
      border: 2px solid #C85103 !important;
      border-radius: 5px; /* Optional: add rounded corners */
      color: #C85103 !important; /* Text color */
      font-weight: bold; /* Optional: make the text bold */
      text-align: center; /* Center the text */
   }

    .mobile-rsvp-direct:hover, .mobile-rsvp-direct.active {
      font-family: 'Madelyn', sans-serif;
      background-color: #f0f0f0; /* Optional: add a hover effect */
      color: black;
      text-decoration: none;
    }
    
    .navbar {
        font-size: 12px;
        opacity: 0.9;
        background: #000 !important;  
    }

    .navbar-nav {
      text-align: left;
    }

    .logo-template {
      font-size: 30px !important;
      margin-left: -10px !important;
      margin-top: -22px;
    }

    .navbar-nav .nav-link{
        margin-top: 0px !important
    }

    .fixed-top {
      position: fixed;
      top: 0;
      z-index: 1030;
      height: 63px;
      opacity: 0.9;
      background: #000 !important;

    }
    
    .navbar-toggler-icon{
        color: #C85103;
    }


    /* Navbar moves to the top when scrolled */
    .navbar-scrolled {
      transform: translateY(0);
      background-color: rgba(0, 0, 0, 0.8);
    }


    /* added */
    
    .navbar-logo {
      position: absolute;
      top: 12px;
      left: 80px;
  }
    
    .navbar-toggle {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    
    .navbar-scrolled {
      background: rgba(0, 0, 0, 0.9) !important;
      transition: background 0.3s;
    }

    .custom-navbar {
      background-color: white;
      transition: background-color 0.3s;
    }
    
    .menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1040;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }
    
    .menu-overlay.open {
      opacity: 1;
      visibility: visible;
    }
    
    .side-panel {
      position: fixed;
      top: 0;
      right: -100%;
      height: 100%;
      width: 300px;
      background: white;
      z-index: 1050;
      transition: right 0.3s;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    }
    
    .side-panel.open {
      right: 0;
    }
    
    .side-panel .nav-link {
      padding: 15px;
      font-size: 40px;
      color: #333;
      text-decoration: none;
      border-bottom: 1px solid #ddd;
      font-family: 'Madelyn', sans-serif;
    }
    
    .side-panel .nav-link:hover {
      background: #f8f8f8;
    }
    
    
    
    
    

  }
  