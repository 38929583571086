
  /* General styles for the section */
  .proposal-section {
    background: #fff;
    padding: 4rem 0;
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  .proposal-section.visible {
    opacity: 1;
  }
 
 .video-soon{
    margin-left: 615px;
    opacity: 0.5;
  }

  .video-container {
    margin-left: 485px
  }

  .embeded-video {
    display: block !important;
  }

  .embeded-video-soon{
    display: none !important;
  }


  /* Laptop 150% */
@media (max-width: 1300px) {
  .video-container {
    margin-left: 25%;
  }

}

/* Laptop 175% */
@media (max-width: 1100px) {
  .video-container {
    margin-left: 25%;
  }

}

/* Laptop 200% */
@media (max-width: 960px) {
  .video-container {
    margin-left: 25%;
  }

}



  @media (min-width: 1024px) {
    

  }

  /* Responsive layout for mobile devices */
  @media (max-width: 767px) {
    .proposal-section{
      padding: 0px !important;
    }

    .video-soon{
      margin-left: 55px;
      width: 70%;
      margin-top: 3rem;
    }

    .video-container{
      margin-left: 0px !important;
      margin-top: 75px !important;
    }
  }