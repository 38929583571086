  .timeline-section {
    padding: 4rem 0;
    background: rgb(244, 244, 244) !important;
    opacity: 0;                       /* Start invisible */
    transition: opacity 1s ease-in;
  }

  .timeline-section.visible {
    opacity: 1;                        /* Fully visible when in view */
  }


  .timeline-hard-coded{
    display: block;
  }

  .timeline-img-place{
    display: none;
  }
  
  /* Center horizontal line using ::before pseudo-element */
  .timeline-line {
    position: relative;
  }
  
  .timeline-line::before {
    content: '';
    position: absolute;
    top: 85px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #6D3724;
    z-index: 1;
  }
  
  /* Icon and vertical line styling */
  .timeline-icon {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
  }
  
  .timeline-img {
    width: 60%;
    position: absolute; /* Position image at the top of the vertical line */
    top: 0;
    transition: top 0.3s ease-in-out;
    animation: bounce 1.5s infinite; /* Apply the bounce animation */
  }

  .timeline-height {
    margin-top: 179px !important;
  }
  
  /* Vertical line styling for left and right branches */
  .vertical-line {
    width: 2px;
    height: 80px;
    background-color: #6D3724;
    position: absolute;
    transform: translateY(-50%);
  }
  
  /* Left-aligned vertical line */
  .vertical-line.left {
    top: 45px !important; /* Start from the top of the horizontal line */
    margin-bottom: 1rem; /* Add a gap between the line and icon */
  }
  
  /* Right-aligned vertical line */
  .vertical-line.right {
    bottom: -190px !important; /* Start from the bottom of the horizontal line */
    margin-top: 1rem; /* Add a gap between the line and icon */
  }

  /* For left side: Position the image at the top of the vertical line */
  .timeline-img.left {
    top: -133px; /* Positioned at the top for left-aligned lines */
  }
  
  /* For right side: Position the image at the bottom of the vertical line */
  .timeline-img.right {
    top: 154px; /* Positioned at the bottom for right-aligned lines */
  }

   /* For left side: Position the image at the top of the vertical line */
   .text-template.left {
    position: absolute;
    top: -55px;
    margin-left: 349px;
    letter-spacing: 2.6px;
    width: 100%;
    color: #6D3724;
  }
  
  /* For right side: Position the image at the bottom of the vertical line */
  .text-template.right {
    position: absolute;
    top: 201px;
    margin-left: 349px;
    letter-spacing: 2.6px;
    width: 100%;
    color: #6D3724;
  }

   /* Desktop Resolution */
 @media (min-width: 1024px) {

 }



  /* Responsive layout for mobile devices */
  @media (max-width: 640px) {
    .timeline-hard-coded{
      display: none !important;
    }

    .sched-image{
      width:70%;
      margin-left: 52px;
    }

  
    .timeline-img-place{
      display: block !important;
    }
  }