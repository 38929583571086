/* CSS file */
  .rsvp-section {
    background: #fff !important;
    padding: 4rem 0;
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  
  .rsvp-section.visible {
    opacity: 1;
  }

  .form-container {
    width: 100%;
    max-width: 700px; /* Set a max-width if needed */
    margin: 0 auto;   /* Center the form */
  }
  
  iframe {
    border: none;
  }
  
  .img-footer{
    background: url('../images/wedding-assets/rustic-footer.png');
    background-position: center;
    background-repeat: repeat-x;
    background-size: 40% auto !important;
    height: 150px !important;
    display: flex;
    align-items: center
  }

  .rsvp-container{
    height: 100vh;
  }

   /* Desktop Resolution */
   @media (min-width: 1024px) {
    .img-footer {
      background-size: 40% auto !important;
      height: 100px !important;
    }
  }

  /* from mobile */
  @media (max-width: 767px) {
    .img-footer{
      background-size: 120% auto !important;
      height: 100px !important;
    }
  }
  