.map-section {
    background: rgb(244, 244, 244) !important;
    padding: 4rem 0;
    opacity: 0;                       /* Start invisible */
    transition: opacity 1s ease-in;    /* Smooth fade-in effect */
}

.map-section.visible {
    opacity: 1;                        /* Fully visible when in view */
}

/* Card styling */
.map-placeholder {
    width: 100%;
    max-width: 350px;
    border: 1px solid black;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .map-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .map-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Button styling */
  .directions-button {
    background-color: #CC5315;
    color: #fff;
    border: none;
  }
  
  .directions-button:hover {
    background-color: #b44a14; /* Darker shade on hover */
  }

  /* 175% Resolution */
    @media (min-resolution: 1.5dppx) and (max-resolution: 2dppx) {
      
    }  

  @media (max-width: 640px) {
    .map-img {
        position: absolute;
        left: 0;
        top: -51px;
        width: 100%;
    }

    .templatedMapcontainer{
        margin-top: 40px !important;
    }

    .margin-bottom-140 {
       margin-bottom: -15px;
    }

    .templatedMapcontainer{
      padding: 14px;
    }

    a.wedding-button {
      font-size: 10px;
    }

    
  }