/* Header CSS */

.header-section-web {
    background-image: url('../images/home-bg-left.png'); /* Specify the path to your image */
    background-size: cover; /* Cover the entire header area */
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
  }

  .web-view{
    display: block;
  }

  .mobile-view{
    display: none;
  }
  
  .header-title {
    font-size: 4rem;
    font-family: "Cormorant-Bold";
    color: #C85103 !important;
    text-shadow: 1px 1px 1px #000 !important;
    letter-spacing: 50px;
  }
  
  .header-subtitle {
    font-size: 2rem;
    font-family: "Agradir";
    color: #C85103 !important;
    text-shadow: 1px 1px 1px #000 !important;
    font-weight: bold;
    letter-spacing: 16px;
  }

  .head-template {
    margin-top: 20% !important;
    margin-left: 3%;
  }


    /* Laptop 150% */
  @media (max-width: 1300px) {
    .head-template {
      margin-top: 15% !important;
    }

  }

  /* Laptop 175% */
  @media (max-width: 1100px) {
    .head-template {
      margin-top: 13% !important;
    }

  }

  /* Laptop 200% */
  @media (max-width: 960px) {
    .head-template {
      margin-top: 10% !important;
    }

  }
  

  /* Mobile */
  @media (max-width: 767px) {
    .header-section-mobile {
      background-image: url('../images/home-mobile.png'); /* Specify the path to your image */
      background-size: cover; /* Cover the entire header area */
      background-position: center;
      background-repeat: no-repeat;
      height: 100vh; /* Full viewport height */
      display: flex;
      align-items: center;
    }

  .web-view{
    display: none;
  }

  .mobile-view{
    display: block;
  }

  .navbar-toggler {
      color: #C85103 !important;
      background-color: #fff !important;
      border: 1px solid #C85103 !important
  }

  .header-title {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    color: #C85103 !important;
    text-shadow: 1px 1px 1px #000 !important;
  }

  .header-subtitle {
    font-size: 1rem;
    color: #C85103;
    text-shadow: 1px 1px 1px #000 !important;
    letter-spacing: 0.5rem
  }

  .head-template{
    margin-top: 75vh !important;
    margin-left: 0px !important;
  }

  .navbar-scrolled {
    background-color: transparent !important;
  }
}